<template>
  <Details
    title="integration partner"
    resource="partners"
    base-path="integrations.index"
    data-provider="$partnerDataProvider"
    routeIdParam="partnerId"
  >
    <template v-slot:default="detailsProps">
      <IntegrationForm
        :on-submit="values => detailsProps.handleSubmit(formatValues(values))"
        :initialValues="detailsProps.record"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import IntegrationForm from "@/views/auth/integrations/IntegrationForm";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";

  export default {
    name: "IntegrationsEdit",
    components: {
      IntegrationForm,
      Details
    },
    mixins: [ModalNavigation],
    methods: {
      formatValues({name, fields}) {
        return {
          name, fields
        }
      }
    }
  }
</script>

<style scoped>

</style>
